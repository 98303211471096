import { defineStore } from 'pinia';

export const useOrderStore = defineStore('orderStore', {
  state: () => ({
    orders: [{}],
  }),
  actions: {
    setOrders(orders: [{}]) {
      this.orders = orders || [{}];
    },
    addOrder(order: {}) {
      this.orders.push(order);
    },
  },
});
